<template>
  <div id="issues" class="alert alert-info">
    <h4>
      Having Issues?
    </h4>
    <p>
      It can happen that the newest files have not loaded because old files are still being cached in your browser.
    </p>
    <ul>
      <li>
        Solution 1:
        <a href="#" @click="reload">Try to reload this site.</a>
      </li>
      <li>
        Solution 2: Follow the instructions
        <a
          href="https://www.netsted.co.za/how-to-clear-your-web-browsers-cache-cookies-and-history/#desktop"
          target="_blank"
          rel="noopener"
        >
          to clear your browser's cache.
        </a>
      </li>
      <li>
        Solution 3: After refreshing the page a couple of times or opening and closing the tab a couple of times, the
        site may update by itself.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>
